body {
  font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: $text-color;
}

/* Navbar
*******************************************************************************/
#navbarTop {
  button.btn-link, a {
    color: transparentize($color-white,0.25);

    &.navbar-brand {
      color: $color-white;
    }
    &.dropdown-item {
      color: $text-color;
    }
  }
}

/* Headings
*******************************************************************************/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 700;
  text-transform: uppercase;
  color: $color-secondary;
}

.footer {

}


/* Sticky footer
*******************************************************************************/
#main > .container, #main > .container-fluid {
  padding: 60px 15px 0;
}
.footer > .container, .footer > .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}
