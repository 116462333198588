@media (min-width: 992px) {
  body {
    padding-top: 0;
    padding-left: 17rem;
  }
}

h1 {
  font-size: 6rem;
  line-height: 5.5rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 2rem;
}

p.lead {
  font-size: 1.15rem;
  font-weight: 400;
}

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.5rem;
}

.social-icons a {
  display: inline-block;
  height: 3.5rem;
  width: 3.5rem;
  background-color: $color-secondary-dark;
  color: #fff !important;
  border-radius: 100%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 3.5rem;
  margin-right: 1rem;
}

.social-icons a:last-child {
  margin-right: 0;
}

.social-icons a:hover {
  background-color: $color-primary;
}

.dev-icons {
  // width: 40rem;
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: space-between;

  .list-inline-item {
    width: 5rem;
    text-align: center;

    i:hover {
      color: $color-primary;
    }
  }
}

@media (min-width:320px) {
  .dev-icons .list-inline-item {
    width: 5.5rem;
  }
}
@media (min-width:360px) {
  .dev-icons .list-inline-item {
    width: 4.5rem;
  }
}

@media (min-width:412px) {
  .dev-icons .list-inline-item {
    width: 4.2rem;
  }
}

@media (min-width:412px) {
  .dev-icons .list-inline-item {
    width: 4.2rem;
  }
}

@media (min-width:600px) {
  .dev-icons .list-inline-item {
    width: 4.5rem;
  }
}

@media (min-width:800px) {
  .dev-icons .list-inline-item {
    width: 4.8rem;
  }
}


#sideNav {
  .navbar-nav .nav-item .nav-link {
    font-weight: 800;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }

  .navbar-toggler:focus {
    outline-color: #e77681;
  }
}

@media (min-width: 992px) {
  #sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 17rem;
    height: 100vh;

    .navbar-brand {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: auto auto 0;
      padding: 0.5rem;

      .img-profile {
        max-width: 10rem;
        max-height: 10rem;
        border: 0.5rem solid rgba(255, 255, 255, 0.2);
      }
    }
    .navbar-collapse {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      width: 100%;
      margin-bottom: auto;

      .navbar-nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;

        .nav-item {
          display: block;
        }

        .nav-link {
          display: block;
        }
      }
    }
  }
}

section {
  &.resume-section {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
    max-width: 75rem;

    .resume-item {
      .resume-date {
        min-width: none;
      }
    }
  }
}
@media (min-width: 768px) {
  section {
    &.resume-section {
      min-height: 100vh;

      .resume-item {
        .resume-date {
          min-width: 18rem;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  section {
    &.resume-section {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }
}

.bg-primary {
  background-color: $color-primary !important;
}

.text-primary {
  color: $color-primary !important;
}

a {
  color: $color-primary;

  &:hover,
  &:focus,
  &:active {
    color: #a71d2a;
  }

}
